import React, { useEffect } from 'react';
import { Toaster, toast } from 'sonner';

export type NotificationType = 'error' | 'info' | 'success';
export interface NotificationMessage {
  message: string;
  notificationType: NotificationType;
}

const Sonner: React.FC<NotificationMessage> = ({ message, notificationType }: NotificationMessage) => {
  useEffect(() => {
    toast.custom((t) => (
      <div
        role="alert"
        className="w-[356px] flex rounded-lg border p-4 [&>svg~*]:pl-7 [&>svg+div]:translate-y-[-3px] [&>svg]:absolute [&>svg]:left-4 [&>svg]:top-4 [&>svg]:text-foreground bg-background text-foreground">
        <div className="w-full">
          <h5 className="mt-1 mb-2 font-medium leading-none tracking-tight">
            {notificationType == 'error' ? 'Error!' : 'Heads up!'}
          </h5>
          <div className="text-sm [&amp;_p]:leading-relaxed">{message}</div>
        </div>
        <div className="cursor-pointer" onClick={() => toast.dismiss(t)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            aria-hidden="true"
            className="w-5 h-5">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </div>
      </div>
    ));
  }, []);

  return (
    <div className="mx-auto">
      <Toaster position="bottom-center" duration={6000} expand={true} />
    </div>
  );
};

export default Sonner;
